import React from "react";
import Helmet from "react-helmet";

import Layout from '../components/layout';

import calendar from '../assets/images/calendar.png'
import plots from '../assets/images/plots.png'
import pack from '../assets/images/package.png'

class Homepage extends React.Component {
    render() {
        const siteTitle = "ThePipeTracker";

        return (
            <Layout>
                <Helmet title={siteTitle} />

                <section id="one" className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <header className="major">
                                <h2>Possibilite que os seus clientes<br />
                                visualizem o estado das suas encomendas</h2>
                            </header>
                            <p>
                                <b>"Bom dia, pode-me dizer qual é o estado da minha encomenda?"</b><br/>
                                Esqueça este tipo de perguntas e crie uma relação mais próxima com os seus clientes, permitindo que estes consultem as próprias encomendas
                                presentes na linha de produção.
                            </p>
                        </div>
                        <div className="col-6">
                            <span className="image fit"><img src={pack} alt="" /></span>
                        </div>
                    </div>
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <span className="image fit"><img src={plots} alt="" /></span>
                        </div>
                        <div className="col-6">
                            <header className="major">
                                <h2>Melhore a eficiência de produção<br />
                                    com gráficos sobre o Pipeline</h2>
                            </header>
                            <p>
                                Toda a sua produção passa agora a estar digitalizada, e desta forma, informação relevante é gerada sobre os métodos de produção da sua empresa.
                                Consulte vários gráficos e estatísticas que podem ajudar a tomar decisões melhores e mais rápidas.
                            </p>
                        </div>
                    </div>
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <header className="major">
                                <h2>Reduza atrasos de entregas
                                    com maior organização da empresa
                                </h2>
                            </header>
                            <p>
                                Com acesso a um calendário de prazos de entrega para encomenda, onde pode facilmente consultar
                                o último local por onde essa encomenda passou e obter previsões dinâmicas de datas de conclusão.
                            </p>
                        </div>
                        <div className="col-6">
                            <span className="image fit"><img src={calendar} alt="" /></span>
                        </div>
                    </div>
                </section>

                <section id="two" className="main style2 special">
                    <div className="grid-wrapper">
                        <div className="col-12">
                            <header className="major">
                                <h2>Qual é o objetivo?</h2>
                            </header>
                            <p>
                                <b>ThePipeTracker</b> visa reduzir ao tempo perdido em contacto sobre estados das encomendas, e ao aumento do nível de produção da empresa.
                                Se o seu negócio tem clientes diferentes e oferece diferentes tipos de serviço, então sabe o que é receber emails/chamadas de clientes que
                                também têm prazos a cumprir, e estão anciosos por saber o estado das coisas. <br/>
                            </p>
                            <header className="major">
                                <h2>Que tipo de informações é que os clientes vêm?</h2>
                            </header>
                            <p>
                                Cada cliente pode apenas consultar as informações referentes às encomendas próprias registadas e o estado/histórico das mesmas dentro da linha de produção.
                                <b>Todos têm prazos a cumprir.</b> Mesmo os clientes dos seus clientes! Deixe que estes possam consultar como está a encomenda e possam retirar as suas próprias
                                conclusões, reduzindo ao número de chamadas no escritório.
                            </p>
                            <div className="col-12">
                                <header className="major">
                                    <h2>Como é que funciona?</h2>
                                </header>
                                <p>
                                    <b>ThePipeTracker</b> é uma Aplicação Web. Significa que é acessível em qualquer lado e em qualquer plataforma!
                                    Pode facilmente gerir a sua empresa enquanto está fora! Consulte de forma simples quem está a trabalhar em quê.
                                    Programe a sua produção consoante a carga atual e as respetivas datas de entrega, com recurso a um conjunto variado de estatísticas e um calendário dedicado.
                                    Obtenha feedback direto, dos seus clientes, com um questionário totalmente customizável e analize o que falhou/teve sucesso em cada encomenda produzida.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="three" className="main style special">
                    <div className="grid-wrapper">
                        <div className="col-4">
                            <span className="icon style5 major fa-paper-plane"></span>
                            <h3>Envio automático de emails</h3>
                            <p>Quando uma encomenda é registada/terminada o cliente é notificado automaticamente por email.</p>
                        </div>
                        <div className="col-4">
                            <span className="icon style5 major fa-cog"></span>
                            <h3>Gestão da Linha de Montagem</h3>
                            <p>Pode customizar o seu Pipeline, de forma a melhor representar a sua produção. Podendo fazer alterações a qualquer momento.</p>
                        </div>
                        <div className="col-4">
                            <span className="icon style5 major fa-users"></span>
                            <h3>Tipos de Utilizador</h3>
                            <p>Existem dois tipos de utilizador. O <b>Administrador</b> com capacidade de customizar a plataforma e <b>Utilizador Normal</b> com funcionalidades limitadas.</p>
                        </div>
                        <div className="col-4">
                            <span className="icon style5 major fa-bar-chart"></span>
                            <h3>Gráficos e Estatísticas</h3>
                            <p>Visualize diferentes gráficos referentes ao Pipeline de forma a o ajudar a tomar decisões futuras ou medidas de melhoria.</p>
                        </div>
                        <div className="col-4">
                            <span className="icon style5 major fa-calendar"></span>
                            <h3>Calendário Dedicado</h3>
                            <p>Consulte e verifique os prazos que tem a cumprir. Obtenha também previsões dinâmicas de datas de entrega para cada encomenda.</p>
                        </div>
                        <div className="col-4">
                            <span className="icon style5 major fa-certificate"></span>
                            <h3>Mais valia em Certificação</h3>
                            <p>Com questionários automáticos de satisfação de clientes, torna-se uma fonte em feedback continuo e uma mais valia para a certificações como <b>ISO:9001</b>.</p>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default Homepage;